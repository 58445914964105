import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, APOLLO_OPTIONS, APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { JwtHelperService } from '@auth0/angular-jwt';
import { split, ApolloClientOptions } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from 'apollo-utilities';
//import { onError } from 'apollo-link-error';
import { onError } from "@apollo/client/link/error";
import { Router } from '@angular/router';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

import { createClient } from 'graphql-ws';
var url="https://admin-gateway-qm3rsdw65a-uc.a.run.app/graphql";
//const uri4 = 'https://dc-api-gateway-dtizfpgwna-uc.a.run.app/api/gateway';
const uri2="http://localhost:3000/graphql";
const uri="https://dc-business-service-production.up.railway.app/api/business";

interface Definintion {
  kind: string;
  operation?: string;
};



export function createApollo(httpLink: HttpLink) {


  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log("the errors", graphQLErrors)
      if (graphQLErrors.length > 0) {
        var index = graphQLErrors.findIndex(error => error.message == "INVALID_TOKEN" || error.message == "AUTH_ERROR" || error.message == "USER_SUSPENDENDED_0R_BANNED" || error.message == "MUST_PROVIDE_AUTHEADER" || error.message == "MUST_PROVIDE_TOKEN");
        if (index >= 0) {
      //    location.href = 'http://localhost:4200/dcadouts/login'
        }
      }

      graphQLErrors.map(({ message, locations, path }) =>

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),

      );


    }

    if (networkError) console.log( networkError);
  });
  const generateRequestId = function () {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const objectId = timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
      return Math.floor(Math.random() * 16).toString(16);
    }).toLowerCase();

    return objectId;
  }

  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
      "Access-Control-Allow-Origin": "*",
      "x-hasura-admin-secret":"ISy8c65v2wXTTun6aRjXyTRFgrhCWtEZysinziMevY3Jq2bOqXW1UCVepCbFM3Bp"

    }
  }));



  const auth = setContext(async (_, { headers }) => {
    // Grab token if there is one in storage or hasn't expired
    let jwtHelper: JwtHelperService
    let token = localStorage.getItem('auth-token');





    if (!token) {
      // An observable to fetch a new token
      // Converted .toPromise()
      localStorage.getItem('auth-token');

      // Set new token to the response (adal puts the new token in storage when fetched)
      token = localStorage.getItem('auth-token');
    }
    // Return the headers as usual
    return {
      headers: {
        Authorization: ' Bearer ' + token,
        ['dc-req-id']: generateRequestId(),
        "x-hasura-admin-secret":"ISy8c65v2wXTTun6aRjXyTRFgrhCWtEZysinziMevY3Jq2bOqXW1UCVepCbFM3Bp"
      },
    };
  });
  const wsHeaders = {
    Authorization: 'Bearer ' + localStorage.getItem('auth-token')
  };

  //wss://dc-business-service-dtizfpgwna-uc.a.run.app/api/business
  const ws = new GraphQLWsLink(createClient({
    url: 'wss://dc-business-service-qm3rsdw65a-uc.a.run.app/api/business',
    lazy: true,
    connectionParams:wsHeaders,
    connectionAckWaitTimeout: 0, // Set the connection acknowledgment timeout in milliseconds (e.g., 10 seconds)
    shouldRetry: (event) => {
      return false
    }


  }));
  const http = httpLink.create({ uri: uri });

  const link1: ApolloLink = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    ws,
    http,
  );
  const link = ApolloLink.from([errorLink, basic, auth, link1]);
  const cache = new InMemoryCache();


  return {
    link,
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only'
      }
    }
  }
}
export function createNamedApollo(httpLink: HttpLink) {
  const http = httpLink.create({
    uri: 'http://localhost:3000/',
  });


  const cache = new InMemoryCache();
  const link = split(
    ({ query }) => {
      const { kind, operation }: Definintion = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    http,
  );
  return {

    link,
    cache
    // ... options


  };
}
@NgModule({
  exports: [
    HttpClientModule,
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      deps: [HttpLink],
      useFactory: createApollo
    }]
})
export class GraphQLModule {
  constructor(private router: Router,) { }

  handleError(message) {
    if (message == "INVALID_TOKEN" || message == "AUTH_ERROR" || message == "USER_SUSPENDENDED_0R_BANNED" || message == "MUST_PROVIDE_AUTHEADER" || message == "MUST_PROVIDE_TOKEN") {
      this.router.navigate(['dcadouts'])
    }
  }
}

